import { Controller } from "@hotwired/stimulus"

// let markersSmall = false;

// Connects to data-controller="map"
export default class extends Controller {
  static values = {
    apiKey: String,
    markers: Array,
  }

  connect() {
    mapboxgl.accessToken = this.apiKeyValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/cyrildrk/cl8shj2zk006j14qgk368yvi1"
    })

    this.fitMapToParis()
    // this.#fitMapToMarkers()
    this.#addMarkersToMap()

    console.log("Current zoom: ", this.map.getZoom())
    this.markersZoomLimit = 11
    if (this.map.getZoom() < this.markersZoomLimit ) {
      this.markersSmall = false;
    } else {
      this.markersSmall = true;
    }

    this.reduceMarkersSize()

    // Set up the zoom event listener
    this.map.on("zoom", this.reduceMarkersSize.bind(this));
  }

  #addMarkersToMap() {
    this.markersValue.forEach((marker) => {

           // Create a HTML element for your custom marker
      const customMarker = document.createElement("div")

      customMarker.setAttribute('data-controller','map-expo-modal')
      customMarker.setAttribute('data-map-expo-modal-path-value',`${marker.path}`)
      customMarker.setAttribute('data-action','click->map-expo-modal#showmodal')

      customMarker.className = "marker"
      customMarker.style.backgroundImage = `url('${marker.image_url}')`
      customMarker.style.backgroundSize = "contain"
      customMarker.style.width = "12px"
      customMarker.style.height = "24px"

      new mapboxgl.Marker({
        element: customMarker,
        anchor: 'bottom' // Set the anchor option to 'bottom' to place the marker at the top
      })
        .setLngLat([ marker.lng, marker.lat ])
        // .setPopup(popup) // Add this (a desactiver avec la modale)
        .addTo(this.map)
    })
  }

  reduceMarkersSize() {
    const currentZoom = this.map.getZoom();

    if (currentZoom < this.markersZoomLimit && !this.markersSmall) {
      console.log("Zoom <", currentZoom);

      const markers = document.querySelectorAll(".marker");

      markers.forEach(marker => {
        marker.style.width = "8px";
        marker.style.height = "16px";
      });

      // Update the state variable to indicate that markers have been resized
      this.markersSmall = true;
    } else if (currentZoom >= this.markersZoomLimit && this.markersSmall) {
      console.log("Zoom >"), currentZoom;

      // Reset marker sizes when zooming back to 8 or above
      const markers = document.querySelectorAll(".marker");

      markers.forEach(marker => {
        marker.style.width = "12px";
        marker.style.height = "24px";
      });

      // Update the state variable to indicate that markers are back to original size
      this.markersSmall = false;
    }
  }

  // #fitMapToMarkers() {
  //   const bounds = new mapboxgl.LngLatBounds()
  //   this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
  //   this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
  // }

  fitMapToParis() {
    const padding = 0; // Adjust the padding to control the extra area shown around Paris
    const parisBounds = new mapboxgl.LngLatBounds(
      [2.2446 - (padding / 2), 48.8156 - (padding / 2)], // Southwest coordinates with padding
      [2.4162 + (padding / 2), 48.9022 + (padding / 2)]  // Northeast coordinates with padding
    );

    this.map.fitBounds(parisBounds, { padding: 70, maxZoom: 15, duration: 0 });
  }

}
