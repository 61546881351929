import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="insert-avatar-in-list"
export default class extends Controller {
  static targets = ["items", "form", "participantsCount"]
  static values = { position: String }

  connect() {
    console.log(this.participantsCountTarget);
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  send(event){
    event.preventDefault()
    fetch(this.formTarget.action, {
      method: "POST",
      headers: { "Accept": "application/json", "X-CSRF-Token": this.csrfToken },
      body: new FormData(this.formTarget)
    })
      .then(response => response.json())
      .then((data) => {
        console.log(data)
        if (data.inserted_item) {
          this.itemsTarget.insertAdjacentHTML("beforeend", data.inserted_item);
          const emptyMessage = document.querySelector(".js-participants-empty");
          if (emptyMessage) {
            emptyMessage.remove();
          }
        } else if (data.destroyed_item) {
          if (document.querySelectorAll(".js-participants-avatar").length == 1) {
            this.itemsTarget.insertAdjacentHTML("beforeend", `<p class="paragraphe mb-3 js-participants-empty">Il n'y a aucun participant pour le moment. Soyez le premier à vous inscrire à cette sortie</p>`)
          }
          document.querySelector(`a[href="/users/${data.destroyed_item}/profile"]`).parentElement.parentElement.remove()
        }
        this.participantsCountTarget.innerHTML = data.participants_count
        this.formTarget.outerHTML = data.form
      })
  }

}
