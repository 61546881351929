import { Controller } from "@hotwired/stimulus"
  import flatpickr from "flatpickr"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  connect() {
    flatpickr(".datepicker", {
      minDate: "today",
      disableMobile: "true"
    });

    flatpickr(".search-dates-flatpickr", {
      minDate: "today",
      mode: "multiple",
      dateFormat: "Y-m-d"
    });
  }
}
