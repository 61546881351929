import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="map-expo-modal"
export default class extends Controller {
  // pas de target car on alimente un container modal map qui n'est pas à l'intérieur du controlleur
  // static targets = ["content"]
  static values = {path: String}

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  showmodal(event) {
    event.preventDefault()

    fetch(this.pathValue, {
      method: "GET",
      headers: { "Accept": "application/json", "X-CSRF-Token": this.csrfToken },
      // body: new FormData(this.heartsTarget)
    })
      .then(response => response.json())
      .then((data) => {
        if (document.getElementById("modal-map")) {

          document.querySelector(".modal-horizontal-scroll").removeEventListener("wheel", onScrollPassive);
          document.getElementById("modal-map").remove();
          document.getElementById("container-modal-map").insertAdjacentHTML("beforeend", data.inserted_item);
          document.querySelector(".modal-horizontal-scroll").addEventListener("wheel", onScrollPassive, {passive: true});

        } else {

          document.getElementById("container-modal-map").insertAdjacentHTML("beforeend", data.inserted_item);
          document.querySelector(".modal-horizontal-scroll").addEventListener("wheel", onScrollPassive, {passive: true});

        }

      })
  }

  hidemodal(event) {
    event.preventDefault()

    if (document.getElementById("modal-map")) {

      document.querySelector(".modal-horizontal-scroll").removeEventListener("wheel", onScrollPassive);
      document.getElementById("modal-map").remove();

    }
  }

}
